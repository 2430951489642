<template lang="html">
  <cupones>
    <h4>Bienvenido a nuestra sección de cupones, por favor selecciona una categoría.</h4>
    <div uk-grid class="uk-grid-match">
      <div v-for="(section,i) in sections" :key="i" class="uk-width-1-4@xl uk-width-1-2@m uk-width-1-1@s uk-flex">
        <router-link :to="'/cupones/'+section.id" class="uk-card uk-card-body uk-card-small uk-margin section-card">
          <h3 class="uk-card-title" style="color:white;">{{section.name}}</h3>
        </router-link>
      </div>
    </div>
  </cupones>
</template>

<script>
import cupones from '@/components/coupons/couponsTemplate.vue'
export default {
  name: 'Coupons',
  computed: {
    sections: function () {
      return this.$store.state.coupons.sections
    }
  },
  components: {
    cupones
  }
}
</script>

<style lang="css" scoped>
</style>
