<template lang="html">
  <con-template>
    <div class="uk-grid-large uk-margin" uk-grid>
      <div class="uk-grid-item-match uk-flex-middle uk-width-expand@m">
        <div class="uk-panel">
          <h1 class="uk-heading-hero">¡Contáctanos!</h1>
          <div class="uk-divider-small"></div>
          <div class="uk-margin uk-width-xlarge">
            <p>¿Tienes dudas, sugerencias, quieres estar en los cupones o solo platicar con nosotros?</p>
            <p>Envíanos tus datos y te contactaremos<br /><br /></p>
          </div>
          <div class="uk-margin">
            <div class="uk-flex-middle uk-grid-small uk-child-width-auto" uk-grid>
              <div>
                <a href="https://www.facebook.com/Torke-107567524342549" target="_blank" title="Facebook" class="uk-button uk-button-text">
                  <span uk-icon="facebook"></span>
                </a>
              </div>
              <div>
                <a href="https://www.instagram.com/torke_mx/" target="_blank" title="Instagram" class="uk-button uk-button-text">
                  <span uk-icon="instagram"></span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="uk-grid-item-match uk-flex-middle uk-width-expand@m">
        <div class="uk-panel">
          <div class="uk-margin">
              <div class="uk-margin">
                <label class="uk-form-label" for="name">Nombre</label>
                <input type="text" name="name" id="form-s-text" placeholder="Nombre..." class="uk-input">
              </div>
              <div class="uk-margin">
                <label class="uk-form-label" for="email">Email</label>
                <input type="text" name="email" id="form-s-text" placeholder="Email..." class="uk-input">
              </div>
              <div class="uk-margin">
                <label class="uk-form-label" for="message">Mensaje</label>
                <textarea name="message" id="messafe" rows="5" placeholder="Mensaje..." class="uk-textarea"></textarea>
              </div>
              <button class="uk-button uk-button-primary">Enviar</button>
          </div>
        </div>
      </div>
    </div>
  </con-template>
</template>

<script>
import conTemplate from '@/components/contact/contactTemplate.vue'
export default {
  name: 'ContactForm',
  components: {
    conTemplate
  }
}
</script>

<style lang="css" scoped>
</style>
