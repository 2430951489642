<template lang="html">
  <div class="">
    <nav class="uk-navbar-container uk-navbar-transparent uk-light">
      <div class="uk-container">
        <div uk-navbar>
          <div class="uk-navbar-left">
            <router-link to="/" class="uk-navbar-item uk-logo">
              <h2><img src="../assets/img/tkeidentcopr-06.png" style="max-width: 9%;" class="uk-visible@m"/>TORKE</h2>
            </router-link>
          </div>
          <div class="uk-navbar-center">
            <ul class="uk-navbar-nav uk-visible@m">
              <li v-for="(menu, i) in menus" class="uk-active" :key="i"><router-link :to="menu.to">{{menu.text}}</router-link></li>
            </ul>
          </div>
          <div class="uk-navbar-right">
            <ul class="uk-navbar-nav uk-visible@m">
              <li>
                <div class="uk-navbar-item">
                  <!--<a href="#" class="uk-button uk-button-small uk-button-default">Iniciar sesion</a>-->
                </div>
              </li>
            </ul>
            <a href="#mobile-menu" class="uk-navbar-toggle uk-hidden@m" uk-navbar-toggle-icon uk-toggle></a>
          </div>
        </div>
      </div>
    </nav>
    <div id="mobile-menu" uk-offcanvas="overlay: true">
      <div class="uk-offcanvas-bar uk-flex uk-flex-column">
        <ul class="uk-nav uk-nav-primary uk-nav-center uk-margin-auto-vertical">
          <li v-for="(menu, i) in menus" :key="i" class="uk-active"><router-link :to="menu.to">{{menu.text}}</router-link></li>
        </ul>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: 'NavBar',
  data () {
    return {
      menus: [
        {
          text: 'INICIO',
          to: '/'
        },
        {
          text: 'CUPONES',
          to: '/cupones'
        },
        {
          text: 'PREGUNTAS FRECUENTES',
          to: 'Preguntas-Frecuentes'
        },
        {
          text: 'CONTACTO',
          to: 'contacto'
        }
      ]
    }
  }
}
</script>

<style lang="css" scoped>
</style>
