<template lang="html">
  <div class="coupons">
    <div class="uk-position-relative uk-background-center-center uk-background-cover" :style="{ backgroundImage: 'url(' + require('@/assets/img/backimage.png') + ')' }" uk-height-viewport>

      <div class="uk-position-cover coupons-background"></div>
      <nav-bar/>
      <div class="uk-position-relative uk-container uk-container-small uk-flex uk-flex-middle" uk-height-viewport="offset-top: true">
        <div class="uk-section uk-section-regular uk-width-1-1 uk-light">
          <div class="uk-flex uk-text-center uk-flex-middle uk-flex-center">
            <div class="uk-width-1-1">
              <slot></slot>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from '@/components/navBar.vue'
export default {
  name: 'couponsTemplate',
  computed: {
  },
  components: {
    NavBar
  }
}
</script>

<style lang="css" scoped>
.coupons-background{
    background-image: linear-gradient(to right top, rgb(60 60 60 / 90%), rgb(255 1 1 / 90%));
}
.section-card{
  background: #00000047;
  cursor: pointer;
  text-decoration:none;
}
.section-card:hover{
  box-shadow: 0 14px 25px rgb(0 0 0 / 16%);
  background: #0000009c;
}
</style>
