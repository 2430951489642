<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'Torke',
  mounted () {
    this.$store.dispatch('init')
  }
}
</script>

<style>
@import '../node_modules/uikit/dist/css/uikit.min.css';
</style>
