<template lang="html">
<faqs>
        <h1 class="uk-text-center">Preguntas Frecuentes</h1>
        <div>
            <div v-for="(faq,i) in faqs" :key="i">
            <hr v-if="trueToOne(faq.is_separator)">
            <h2 v-if="trueToOne(faq.is_separator)"><b v-html="faq.question"></b></h2>
            <hr v-if="trueToOne(faq.is_separator)">
            <h3 v-if="!trueToOne(faq.is_separator)"><b v-html="faq.question"></b></h3>
            <p v-if="!trueToOne(faq.is_separator)"><b v-html="replacenl(faq.answer)"></b></p>
            </div>
        </div>
</faqs>
</template>

<script>
import faqs from '@/components/faqs/faqsTemplate.vue'
const axios = require('axios')
export default {
  data () {
    return {
        faqs: []
    }
  },
  methods: {
    getFaqs: function () {
      var vue = this;
      axios.get('https://accounts.gettorke.com/faqs')
      .then(function (response) {
        // handle success
        vue.faqs = response.data;
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
    },
    trueToOne: function (number) {
      if(number == "1"){
        return true;
      }else{
        return false;
      }
    },
    replacenl: function (text) {
      return text.replace(/(?:\r\n|\r|\n)/g, '<br>');
    }
  },
  mounted(){
    this.getFaqs();
  },
  components:{
    faqs
  }
}
</script>

<style lang="css" scoped>
.logoImage{
    width:25%;
  }

.nameImage{
    margin-left: 4%;
    width: 58%;
  }
</style>
