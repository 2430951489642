<template lang="html">
  <cupones>
    <div v-if="loaded">
      <h4>Selecciona el cupón de su interés.</h4>
      <div class="uk-grid-match" uk-grid>
        <div v-for="(coupon,i) in coupons" :key="i" class="uk-width-1-4@xl uk-width-1-2@m uk-width-1-1@s">
        <router-link :to="'/cupon/'+coupon.id"  class="uk-card section-card uk-margin">
           <div class="uk-card-media-top">
               <img :src="coupon.image" alt="">
           </div>
           <div class="uk-card-body">
               <h3 class="uk-card-title">{{coupon.title}}</h3>
           </div>
       </router-link>
     </div>
      </div>
    </div>
    <span v-if="!loaded" uk-spinner="ratio: 4.5"></span>
  </cupones>
</template>

<script>
import cupones from '@/components/coupons/couponsTemplate.vue'
export default {
  name: 'CouponsList',
  props: {
    id: String
  },
  mounted () {
    this.$store.dispatch('getCouponsList',this.id)
  },
  computed: {
    coupons: function () {
      return this.$store.state.coupons.coupons
    },
    loaded: function () {
      return this.$store.state.coupons.isLoaded
    }
  },
  components: {
    cupones
  }
}
</script>

<style lang="css" scoped>
</style>
