import Vue from 'vue'
import Vuex from 'vuex'
import coupons from './coupons'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    url: 'https://app.gettorke.com/torke/public'
  },
  mutations: {
  },
  actions: {
    init: function (context) {
      context.dispatch('getSections')
    }
  },
  modules: {
    coupons
  }
})
