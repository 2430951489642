<template>
  <div class="home">

<div class="uk-position-relative uk-background-center-center uk-background-cover" :style="{ backgroundImage: 'url(' + require('@/assets/img/backimage.png') + ')' }" uk-height-viewport>

	<div class="uk-position-cover home-background"></div>
	<nav-bar/>
	<div class="uk-position-relative uk-container uk-container-small uk-flex uk-flex-middle" uk-height-viewport="offset-top: true">
		<div class="uk-section uk-section-regular uk-width-1-1 uk-light">
			<div class="uk-flex uk-text-center uk-flex-middle uk-flex-center">
				<div class="uk-width-1-1">
					<h1 class="uk-heading-small">TORKE</h1>
          <transition name="bounce" mode="out-in">
          <div v-if="!conocerMas" class="">
            <div class="uk-text-lead">Tu copiloto 24/7.</div>
            <div class="uk-margin-large-top">
              <p>
                <a href="#" class="uk-button uk-button-default uk-button-xlarge" @click="showMore()">Conocer más</a>
              </p>
            </div>
          </div>
          <div v-if="conocerMas">
          <div>TORKE te ayudará a recordar cuando debes realizar los pagos de tenencia, verificación, póliza de
seguro, cuotas mensuales (En caso de que el auto haya sido adquirido por medio de
financiamiento), cuando debe renovar tu licencia, cuando debes llevar su auto a servicio,
ahorrándose así multas por pagos tardíos, todo esto se hará atraves de una notificación a tu
dispositivo móvil.<br><br>
Podrás consultar el reglamento vehicular del Estado de México cada vez que lo necesites, podrás
consultar los tipos de licencia que existen, sí es que tu vehículo paga refrendo o tenencia ( En caso
de pagar tenencia, la aplicación calculará cuanto debes pagar), podras consultar en un solo click,
tu tenencia, refrendo, multas y repuve de tu vehículo.<br><br>
La aplicación tiene una sección de finanzas, en la cuál podrás calcular y administrará los pagos
referentes a su vehículo.<br><br>
La aplicación ayudará al usuario a localizar establecimientos requeridos para su auto cercanos a su
ubicación.<br><br>
Por último, TORKE brindará al usuario noticias y medidas relevantes referidas al ámbito vehicular</div>
          <div class="uk-margin-top" uk-grid>
            <div v-for="(image,i) in screenshots" :key="i" class="uk-width-1-3@m">
              <img :src="image">
            </div>
          </div>
          <div class="uk-margin-large-top">
            <p>
              <a href="#" class="uk-button uk-button-default uk-button-xlarge" @click="showMore(false)">Ver menos</a>
            </p>
          </div>
          </div>
        </transition>
          <div class="uk-margin-top" uk-grid>
            <div class="uk-width-1-2@m">
              <a href='https://play.google.com/store/apps/details?id=com.gettorke.torke&hl=es_MX&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                <img alt='Disponible en Google Play' style="max-width: 50%;" src='https://play.google.com/intl/es-419/badges/static/images/badges/es-419_badge_web_generic.png'/>
              </a>
            </div>
            <div class="uk-width-1-2@m">
              <a href='https://apps.apple.com/mx/app/torke/id1541079348'>
                <img alt='Disponible en Google Play' class="appstore-badge" src="../assets/img/appstore-badge.svg"/>
              </a>
            </div>
          </div>
				</div>
			</div>
		</div>
	</div>
</div>
  </div>
</template>

<script>
import NavBar from '@/components/navBar.vue'
export default {
  name: 'Home',
  data () {
    return {
      conocerMas: false,
      screenshots: [
        require('../assets/img/screnshots/1.png'),
        require('../assets/img/screnshots/2.png'),
        require('../assets/img/screnshots/3.png'),
        require('../assets/img/screnshots/4.png'),
        require('../assets/img/screnshots/5.png'),
        require('../assets/img/screnshots/6.png'),
        require('../assets/img/screnshots/7.png')
      ]
    }
  },
  methods: {
    showMore: function (show=true) {
      this.conocerMas = show
    }
  },
  components: {
    NavBar
  }
}
</script>

<style scoped>
  .home-background{
      background-image: linear-gradient(to right top, rgb(60 60 60 / 90%), rgb(0 0 0 / 90%));
  }
  .bounce-enter-active {
    animation: bounce-in .5s;
  }
  .bounce-leave-active {
    animation: bounce-in .5s reverse;
  }
  .appstore-badge{
    width: 40%;
    margin-top: 12px;
  }
  @keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
  }
</style>
