/*eslint-disable*/
const axios = require('axios');
const coupons = {
  state: {
    isLoaded: false,
    sections: [],
    coupons: [],
    coupon: {}
  },
  mutations: {
    updateCouponsList: function (state,data) {
      state.isLoaded = true
      state.coupons = data
    },
    updateCoupon: function (state,data) {
      state.isLoaded = true
      state.coupon = data
    }

  },
  actions: {
    getSections: function (context) {
      axios.get(context.rootState.url+'/coupons')
      .then(function (response) {
        // handle success
        context.state.sections = response.data;
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
    },
    getCouponsList: function (context, id) {
      context.state.isLoaded = false
      axios.get(context.rootState.url+'/coupons/'+id)
      .then(function (response) {
        // handle success
        context.commit('updateCouponsList', response.data)

      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
    },
    getCoupon: function (context, id) {
      context.state.isLoaded = false
      axios.get(context.rootState.url+'/coupon/'+id)
      .then(function (response) {
        // handle success
        context.commit('updateCoupon', response.data)

      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
    }
  },
  getters: {

  }
}

module.exports = coupons
