import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Uikit from 'uikit'
import Icons from 'uikit/dist/js/uikit-icons'
Uikit.use(Icons)

Vue.config.productionTip = false

Vue.mixin({
  data: function () {
    return {
      get Uikit () {
        return Uikit
      }
    }
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
