<template lang="html">
  <cupones>
    <div v-if="loaded">
      <div class="uk-card section-card uk-width-1-2@m uk-margin-bottom">
        <div class="uk-card-header">
          <img style="width:100%;" :src="coupon.image">
          <div class="uk-width-expand">
            <h3 class="uk-card-title uk-margin-remove-bottom">{{coupon.title}}</h3>
          </div>
        </div>
        <div class="uk-card-body">
          <p v-if="coupon.phone != null && coupon.phone != ''"><b>Teléfono:</b> {{coupon.phone}}</p>
          <p v-if="coupon.email != null && coupon.email != ''"><b>Email:</b> {{coupon.email}}</p>
          <p  v-if="coupon.description != null && coupon.description != ''" v-html="'<b>Descripción:</b><br> '+coupon.description"></p>
        </div>
        <div class="uk-card-footer">
          <img style="max-width:50%;" :src="coupon.qr">
        </div>
      </div>
    </div>
    <span v-if="!loaded" uk-spinner="ratio: 4.5"></span>
  </cupones>
</template>

<script>
import cupones from '@/components/coupons/couponsTemplate.vue'
export default {
  name: 'Coupon',
  props: {
    id: String
  },
  mounted () {
    this.$store.dispatch('getCoupon', this.id)
  },
  computed: {
    coupon: function () {
      return this.$store.state.coupons.coupon
    },
    loaded: function () {
      return this.$store.state.coupons.isLoaded
    }
  },
  components: {
    cupones
  }
}
</script>

<style lang="css" scoped>
</style>
