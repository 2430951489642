import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Coupons from '../views/Coupons.vue'
import CouponsList from '../views/CouponsList.vue'
import Coupon from '../views/Coupon.vue'
import Faqs from '../views/Faqs.vue'
import Contact from '../views/Contact.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/cupones',
    name: 'Coupons',
    component: Coupons
  },
  {
    path: '/cupones/:id',
    component: CouponsList,
    props: true
  },
  {
    path: '/cupon/:id',
    component: Coupon,
    props: true
  },
  {
    path: '/preguntas-frecuentes',
    component: Faqs
  },
  {
    path: '/contacto',
    component: Contact
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
